import { CellValueType } from "../models/settings";

export class HexakaiCellValueMapper {

    private static standard = new Array(16).fill(0).map((_, ind) => `${ind.toString(16).toUpperCase()}`);
    private static numbersOnly = new Array(16).fill(0).map((_, ind) => `${ind}`);
    private static lettersOnly = new Array(16).fill(0).map((_, ind) => String.fromCharCode("A".charCodeAt(0) + ind));;
    private static emojis = ['😀', '😂', '🤔', '😍', '😎', '🤩', '😊', '🙃', '😉', '🥳', '😴', '🤯', '🥸', '🤠', '🥺', '😇'];
    private static greekAlphabet = ['α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π'];

    public static getValue(
        value: string,
        type: CellValueType
    ): string {
        if (!value) {
            return value;
        }

        switch (type) {
            case CellValueType.numbersOnly:
                return this.numbersOnly[parseInt(value, 16)] ?? value;
            case CellValueType.lettersOnly:
                return this.lettersOnly[parseInt(value, 16)] ?? value;
            case CellValueType.emojis:
                return this.emojis[parseInt(value, 16)] ?? value;
            case CellValueType.greekAlphabet:
                return this.greekAlphabet[parseInt(value, 16)] ?? value;
            default:
                return value;
        }
    }

    public static getCanonicalValue(
        value: string,
        type: CellValueType
    ): string {
        let index!: number;
        switch (type) {
            case CellValueType.numbersOnly:
                index = this.numbersOnly.indexOf(value);
                break;
            case CellValueType.lettersOnly:
                index = this.lettersOnly.indexOf(value);
                break;
            case CellValueType.emojis:
                index = this.emojis.indexOf(value);
                break;
            case CellValueType.greekAlphabet:
                index = this.greekAlphabet.indexOf(value);
                break;
            default:
                return value;
        }
        
        if (index === -1) {
            throw "Invalid";
        }

        return this.standard[index];
    }

    public static getPossibleValues(
        gameSize: number,
        type: CellValueType
    ): string[] {
        let values: string[];
        switch (type) {
            case CellValueType.numbersOnly:
                values = this.numbersOnly;
                break;
            case CellValueType.lettersOnly:
                values = this.lettersOnly;
                break;
            case CellValueType.emojis:
                values = this.emojis;
                break;
            case CellValueType.greekAlphabet:
                values = this.greekAlphabet;
                break;
            default:
                values = this.standard;
                break;
        }

        return values.slice(0, gameSize);
    }
}