import { LitElement, html, css, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ClientService } from '../client-service/client-service';
import { HexakaiCellValueMapper } from '../hexakai-board/hexakai-cell-value-mapper';
import { CellValueType } from '../models/settings';

@customElement('hex-cell')
export class HexCell extends LitElement {

    static properties = {
        showInnerBorder: { type: Boolean, reflect: true },
        showAnimations: { type: Boolean, reflect: true },
        isSpecialFilterOn: { type: Boolean, reflect: true }
    };

    static styles = css`
        :host {
            display: block;
        }
        .hex-container {
            position: relative;
            font-size: var(--font-size, 2.8cqh);
            height: 100%;
            aspect-ratio: 866 / 1000; /* Using the ratio derived from sqrt(3)/2 */
        }
        .hex {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            aspect-ratio: 866 / 1000; /* Using the ratio derived from sqrt(3)/2 */
            width: calc(100% - 4px); /* Reduce width to accommodate border */
            height: calc(100% - 4px); /* Reduce height to accommodate border */
            clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
            color: var(--body-color);
            background-color: var(--hex-choice-color-override, var(--background-color));
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background-color 0.3s ease;
            user-select: none;
        }
        .hex-value {
            z-index: 2;
        }
        .hex-value:hover, .hex-inner-border:hover {
            background-color: var(--hover-color);
        }
        .hex-background {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: calc(100% + var(--hex-border-increase, 4px));
            height: calc(100% + var(--hex-border-increase, 4px));
            background-color: var(--body-color);
        }
        .hex-inner-border {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 70%;
            height: 70%;
            background-color: var(--hex-inner-border-color);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .disabled {
            cursor: default !important;
            background-color: var(--background-color-disabled) !important;
        }
        .disabled:hover {
            background-color: var(--background-color-disabled) !important;
        }
        .hex-inner-border-disabled:hover {
            background-color: var(--hex-inner-border-color) !important;
        }
        .hex-pencils-left {
            position: absolute;
            height: fit-content;
            width: fit-content;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            top: 24%;
            left: 0.22em;
            overflow: hidden;
        }
        
        .hex-pencils-right {
            position: absolute;
            height: fit-content;
            width: fit-content;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            top: 24%;
            right: 0.22em;
            overflow: hidden;
        }
        .hex-pencil-top {
            position: absolute;
            height: fit-content;
            width: fit-content;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            top: 0%;
            margin-top: 0.22em;
            overflow: hidden;
            left: 50%;
            transform: translateX(-50%);
        }
        .hex-pencil-bottom {
            position: absolute;
            height: fit-content;
            width: fit-content;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            bottom: 0%;
            margin-bottom: 0.22em;
            overflow: hidden;
            left: 50%;
            transform: translateX(-50%);
        }
        .hex-pencil-mark {
            margin: 0;
            width: fit-content;
            font-size: calc(var(--font-size, 2.8cqh) * 0.4) !important;
        }
        p {
            margin: 0;
            padding: 0;
        }
        :host([showInnerBorder]) .hex-inner-border,
        :host([isSpecialFilterOn]) .hex-inner-border {
            display: flex;
            z-index: 1;
        }
        :host([showAnimations]) .pulse-once {
            animation: pulse 0.4s ease forwards;
        }    
        /* Animation to grow and shrink */
        @keyframes pulse {
            0%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.3);
            }
        }
    `;

    private clientService = new ClientService();

    @property({ type: String }) value: string = '';
    @property({ type: Set<string> }) pencils = new Set<string>();
    @property({ type: Number }) row: number = 0;
    @property({ type: Number }) col: number = 0;
    @property({ type: CellValueType }) cellValueType = CellValueType.standard;

    @property({ attribute: false }) onConnected: ((cell: HexCell) => void) | undefined;

    private showInnerBorder = false;
    private showAnimations = true;
    private isSpecialFilterOn = false;

    private clickListener: any;
    private contextMenuListener: any;

    private clickContextEventDefer = false;
    private clickContextDoDispatch = false;
    private firstOfDoubleLeftClick = false;
    private resizeObserver!: ResizeObserver;
    private resizeThrottleAwait: Promise<void> = Promise.resolve();
    private isResizeThrottleWaiting = false;
    private lastContainerHeight = 0;

    private pencilsOverflowing = false;

    private leftRightDelay = 120;

    private numPencilInLeftVisible = this.clientService.getConfig().gameBoard.hexCell.numPencilsVisible;

    private isDisabled = false;

    get disabled(): boolean {
        return this.isDisabled;
    }

    set disabled(is: boolean) {
        if (this.isDisabled === is) {
            return;
        }

        if (is) {
            this.setAttribute("disabled", "true");
        } else {
            this.removeAttribute("disabled");
        }
        this.isDisabled = is;
        this.requestUpdate();
    }

    constructor() {
        super();
    }

    connectedCallback(): void {
        super.connectedCallback();

        if (this.onConnected) {
            this.onConnected(this);
        }

        this.setupLeftRightClickListeners();
    }

    firstUpdated() {
        const container = this.shadowRoot!.querySelector('.hex-container') as HTMLElement;
        const pencilsLeft = this.shadowRoot!.querySelector('.hex-pencils-left') as HTMLElement;

        if (container && pencilsLeft) {
            this.resizeObserver = new ResizeObserver(() => {
                this.checkPencilsLeftHeight(container, pencilsLeft);
            });

            this.resizeObserver.observe(container);
            this.resizeObserver.observe(pencilsLeft);

            // Initial check
            this.checkPencilsLeftHeight(container, pencilsLeft);
        }
    }

    updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);
        // Check if the "disabled" attribute is present
        this.disabled = this.hasAttribute('disabled')
            && this.getAttribute('disabled') !== "false";
    }

    private setupLeftRightClickListeners(): void {
        this.clickListener = this.addEventListener('click', (e: MouseEvent) => {
            // @ts-ignore
            if (e._hexAllowDispatch) {
                return true;
            }

            if (this.firstOfDoubleLeftClick) {
                this.dispatchEvent(new CustomEvent('double-click', {
                    // @ts-ignore
                    target: this
                }));
                this.clickContextEventDefer = false;
                this.clickContextDoDispatch = false;
                this.firstOfDoubleLeftClick = false;
            } else if (this.clickContextEventDefer) {
                this.dispatchEvent(new CustomEvent('left-right-click', {
                    // @ts-ignore
                    target: this
                }));
                this.clickContextEventDefer = false;
                this.clickContextDoDispatch = false;
                this.firstOfDoubleLeftClick = false;
            } else {
                this.clickContextDoDispatch = true;
                this.clickContextEventDefer = true;
                this.firstOfDoubleLeftClick = true;
                setTimeout(() => {
                    if (this.clickContextDoDispatch) {
                        this.clickContextEventDefer = false;
                        const newEvent = new Event('click', e);
                        // @ts-ignore
                        newEvent._hexAllowDispatch = true;
                        this.dispatchEvent(newEvent);
                        this.clickContextDoDispatch = false;
                    }
                    this.firstOfDoubleLeftClick = false;
                }, this.leftRightDelay);
            }

            // prevent from bubbling up
            e.stopPropagation();
            e.preventDefault();
        }, { passive: false, capture: true });

        if (this.clientService.getConfig().gameBoard.hexCell.rightClickStrategy === 'contextmenu') {
            this.contextMenuListener = this.addEventListener('contextmenu', (e: Event) => {
                console.debug(`[HexCell] context menu`);
                // @ts-ignore
                if (e._hexAllowDispatch) {
                    return true;
                }

                if (this.clickContextEventDefer) {
                    this.dispatchEvent(new CustomEvent('left-right-click', {
                        // @ts-ignore
                        target: this
                    }));
                    this.clickContextEventDefer = false;
                    this.clickContextDoDispatch = false;
                    this.firstOfDoubleLeftClick = false;
                } else {
                    this.clickContextDoDispatch = true;
                    this.clickContextEventDefer = true;
                    this.firstOfDoubleLeftClick = false;
                    setTimeout(() => {
                        if (this.clickContextDoDispatch) {
                            this.clickContextEventDefer = false;
                            const newEvent = new Event('contextmenu', e);
                            // @ts-ignore
                            newEvent._hexAllowDispatch = true;
                            this.dispatchEvent(newEvent);
                            this.clickContextDoDispatch = false;
                        }
                        this.firstOfDoubleLeftClick = false;
                    }, this.leftRightDelay);
                }

                // prevent from bubbling up
                e.stopPropagation();
                e.preventDefault();
            }, { passive: false, capture: true });
        } else {
            // Set up long-press detection logic
            let pressTimer: number | undefined;

            this.addEventListener('pointerdown', (e: PointerEvent) => {
                startPressTimer(e);
            });

            this.addEventListener('pointerup', () => {
                clearPressTimer();
            });

            this.addEventListener('pointerleave', () => {
                clearPressTimer(); // Ensure timer is cleared when pointer leaves the element
            });

            this.addEventListener('pointercancel', () => {
                clearPressTimer(); // Handle interrupted interactions
            });

            const that = this;
            function startPressTimer(e: PointerEvent) {
                clearPressTimer(); // Ensure no timer is running

                // Start a 2-second timer to detect long-press
                pressTimer = window.setTimeout(() => {
                    that.clickContextEventDefer = false;
                    const newEvent = new Event('contextmenu', e);
                    // @ts-ignore
                    newEvent._hexAllowDispatch = true;
                    that.dispatchEvent(newEvent);
                    that.clickContextDoDispatch = false;
                }, 1_100);
            }

            function clearPressTimer() {
                if (pressTimer !== undefined) {
                    clearTimeout(pressTimer);
                    pressTimer = undefined;
                }
            }
        }
    }

    addPencil(mark: string): void {
        this.pencils.add(mark);
        this.requestUpdate();
    }

    removePencil(mark: string): void {
        this.pencils.delete(mark);
        this.requestUpdate();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEventListener('click', this.clickListener);
        this.removeEventListener('contextmenu', this.contextMenuListener);
    }

    _emitLeftRightClicked() {
        this.dispatchEvent(new CustomEvent('left-right-click', {
            detail: {
                target: this
            },
            bubbles: true,
            composed: true
        }));
    }

    displayInnerBorder(): void {
        this.showInnerBorder = true;
    }

    hideInnerBorder(): void {
        this.showInnerBorder = false;
    }

    displayAnimations(): void {
        this.showAnimations = true;
    }

    hideAnimations(): void {
        this.showAnimations = false;
    }

    /**
     * Apply the pulse animation once
     */
    pulse(): void {
        const hexElement = this.shadowRoot!.querySelector('.hex-container')! as HTMLElement;
        hexElement.classList.add('pulse-once');

        hexElement.style.zIndex = "50";
        this.style.zIndex = "50";
        this.parentElement!.style.zIndex = "50";

        const removeAnimation = () => {
            hexElement.classList.remove('pulse-once');
            hexElement.removeEventListener('animationend', removeAnimation);
            hexElement.style.zIndex = "initial";
            this.style.zIndex = "initial";
            this.parentElement!.style.zIndex = "initial";
        };

        hexElement.addEventListener('animationend', removeAnimation);
    }

    showSpecialFilter(): void {
        this.isSpecialFilterOn = true;
    }

    hideSpecialFilter(): void {
        this.isSpecialFilterOn = false;
    }

    render() {
        const pencils = [...this.pencils].sort((a, b) => {
            return parseInt(a, 16) - parseInt(b, 16);
        }).map(value => HexakaiCellValueMapper.getValue(value, this.cellValueType));

        const n = this.numPencilInLeftVisible;

        const concatPad = ["-"];
        for (let i = 1; i < n; i++) {
            concatPad.push("-");
        }

        const pencilTop = pencils.slice(0, 1);
        const pencilsLeft = pencils.slice(1, 1 + n).concat(concatPad).slice(0, n);
        const pencilsRight = pencils.slice(1 + n, 1 + 2 * n).concat(concatPad).slice(0, n);
        const pencilBottom = pencils.length > 2 + 2 * n || (this.pencilsOverflowing && pencils.length > 0)
            ? "+"
            : pencils.length === 2 + 2 * n ? pencils[1 + 2 * n] : "";

        const hidden = this.pencilsOverflowing ? "visibility: hidden;" : "";

        const dV = HexakaiCellValueMapper.getValue(this.value, this.cellValueType);

        return html`
            <div class="hex-container">
                <div class="hex hex-background"></div>
                <div class="hex hex-inner-border ${this.isDisabled ? 'hex-inner-border-disabled' : ''}">
                    <p>${dV}</p>
                </div>
                <div class="hex ${this.isDisabled ? 'disabled' : ''}">
                    <p>${dV}</p>
                </div>
                <div class="hex-pencils-left">
                    ${pencilsLeft.map(mark => {
            const isHidden = this.pencilsOverflowing || mark === "-";
            const hiddenStyle = isHidden ? "visibility: hidden;" : "";
            return html`<div class="hex-pencil-mark" style="${hiddenStyle}">${mark}</div>`
        })}
                </div>
                <div class="hex-pencils-right">
                    ${pencilsRight.map(mark => {
            const isHidden = this.pencilsOverflowing || mark === "-";
            const hiddenStyle = isHidden ? "visibility: hidden;" : "";
            return html`<div class="hex-pencil-mark" style="${hiddenStyle}">${mark}</div>`
        })}
                </div>
                <div class="hex-pencil-top">
                    <div class="hex-pencil-mark" style="${hidden}">${pencilTop}</div>
                </div>
                <div class="hex-pencil-bottom">
                    <div class="hex-pencil-mark">${pencilBottom}</div>
                </div>
            </div>
        `;
    }

    private async checkPencilsLeftHeight(
        container: HTMLElement,
        pencilsLeft: HTMLElement,
        tryIncrement = true
    ) {
        // wait for throttle
        if (this.isResizeThrottleWaiting) {
            return;
        }
        this.isResizeThrottleWaiting = true;
        await this.resizeThrottleAwait;

        const containerHeight = container.offsetHeight;
        const pencilsLeftHeight = pencilsLeft.offsetHeight;

        // Check if pencilsLeft has too much height
        const sizeMult = 0.55;
        const isOverflowing = pencilsLeftHeight > containerHeight * sizeMult;

        if (isOverflowing) {
            if (this.numPencilInLeftVisible > 2) {
                console.debug(`[HexCell] is overflowing, so decrementing num pencils visible`);
                this.numPencilInLeftVisible--;
            } else {
                this.pencilsOverflowing = true;
            }
        } else {
            this.pencilsOverflowing = false;
            if (tryIncrement && containerHeight !== this.lastContainerHeight) {
                requestAnimationFrame(() => {
                    //console.debug(`[HexCell] checking if we can increment num pencils visible`);
                    this.numPencilInLeftVisible++;
                    this.checkPencilsLeftHeight(container, pencilsLeft, false);
                });
            }
        }

        this.isResizeThrottleWaiting = false;
        this.resizeThrottleAwait = new Promise<void>(resolve => {
            setTimeout(resolve, 20);
        });
        this.lastContainerHeight = containerHeight;
        this.requestUpdate();
    }
}