import EventEmitter from "eventemitter3";
import { CellValueType, Settings } from "../models/settings";
import { StorageService } from "../models/storage-service";

export class SettingsService {

    private static instance: SettingsService;
    private SETTINGS_KEY = "user-settings";

    private eventEmitter = new EventEmitter();

    private settingsCache!: Settings;

    constructor(
        private storageService: StorageService
    ) {
        if (SettingsService.instance) {
            return SettingsService.instance;
        }

        SettingsService.instance = this;
    }

    static getSettingsTexts(): {[key in keyof Settings]: string} {
        return {
            boardSoundsEnabled: "This setting allows you to enable or disable game sounds.",
            cellValueType: "This setting allows you to change how you display cell values. Options include standard, numbers only, letters only, greek alphabet, and even emojis.",
            unifiedCellMenuEnabled: "This setting changes the way you assign cell values, colors, and pencil markings. When unified menu is enabled, all controls appear simultaneously. When disabled, you'll need to right click or long press a cell to display the color and pencil marking options."
        };
    }

    async getSettings(): Promise<Settings> {
        if (!this.settingsCache) {
            this.settingsCache = await this.storageService
                .get<Settings>(this.SETTINGS_KEY)
                .then((settings) => settings || this.getDefaultSettings())
                .then((settings) => {
                    // in case new settings were added, add the defaults in
                    return {
                        ...this.getDefaultSettings(),
                        ...settings
                    }
                });
            console.log(`[SettingsService] loaded settings`, this.settingsCache);
        }
        
        // hacky workaround
        return JSON.parse(JSON.stringify(this.settingsCache));
    }

    async updateSettings(newSettings: Partial<Settings>): Promise<void> {
        const existingSettings = await this.getSettings();
        const fullNewSettings = {
            ...existingSettings,
            ...newSettings
        };
        await this.storageService.set(this.SETTINGS_KEY, fullNewSettings);

        // hacky workaround
        this.settingsCache = JSON.parse(JSON.stringify(fullNewSettings));
        console.log(`[SettingsService] updated settings`, this.settingsCache);
        this.eventEmitter.emit('settings-updated', JSON.parse(JSON.stringify(fullNewSettings)));

    }

    onSettingsUpdated(callback: (settings: Settings) => void): void {
        this.eventEmitter.on('settings-updated', async () => {
            try {
                await callback(this.settingsCache);
            } catch (err) {
                console.warn(`[SettingsService] error caught on settings updated callback`, err);
            }
        });
    }

    private getDefaultSettings(): Settings {
        return <Settings>{
            boardSoundsEnabled: true,
            cellValueType: CellValueType.standard,
            unifiedCellMenuEnabled: false
        };
    }
}