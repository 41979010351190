import { HexCell } from "../components/hex-cell";
import { HexakaiCellValueMapper } from "../hexakai-board/hexakai-cell-value-mapper";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { CellValueType } from "../models/settings";
import { SettingsService } from "../settings-service/settings-service";

const settingsService = new SettingsService(
    new LocalStorageService()
);

document.addEventListener('DOMContentLoaded', async () => {
    let settings = await settingsService.getSettings();
    updateValuesInPage(settings.cellValueType);

    settingsService.onSettingsUpdated(newSettings => {
        settings = newSettings;
        updateValuesInPage(settings.cellValueType);
    });

    window.addEventListener('update-hex-values', () => {
        updateValuesInPage(settings.cellValueType);
    })
});

function updateValuesInPage(cellValueType: CellValueType) {
    for (let i=0; i<16; i++) {
        for (const item of document.querySelectorAll(`.hex-value-${i}`)) {
            const el = item as HTMLElement;
            if (el instanceof HexCell) {
                el.value = HexakaiCellValueMapper.getValue(`${i}`, cellValueType);
            } else {
                el.innerText = HexakaiCellValueMapper.getValue(`${i}`, cellValueType);
            }
        }
    }
}