export enum CellValueType {
    standard = "Standard",
    numbersOnly = "Numbers Only",
    lettersOnly = "Letters Only",
    emojis = "Emojis",
    greekAlphabet = "Greek Alphabet"
}

export const CellValueTypes = Object.values(CellValueType);

export interface Settings {
    boardSoundsEnabled: boolean;
    cellValueType: CellValueType;
    unifiedCellMenuEnabled: boolean;
}